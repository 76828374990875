import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useGet, usePost, usePut } from '../../API/request';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { Loader } from '../../components/loader/Loader';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { NavigateHeader } from '../../components/navigate-header/NavigateHeader';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';

const UserAdd = () => {
  const navigate = useNavigate();
  const postU = usePost();
  const getU = useGet();
  const putU = usePut();

  const [isLoaded, setIsLoaded] = useState(true);

  const [isValidateEmail, setIsValidateEmail] = useState(true);
  const [values, setValues] = useState({
    email: '',
    username: '',
    lastname: '',
    role: '',
    phone: '',
    password: '',
    confirm: ''
  });
  const [errors, setErrors] = useState({
    email: false,
    username: false,
    lastname: false,
    phone: false,
    role: false,
    password: false,
    confirm: false
  });
  const [roles, setRoles] = useState([]);

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const { alert, showAlert } = useShowAlert();

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const resetDisabled = () => setSubmitDisabled(() => false);

  const handleChangeEmail = (event) => {
    const reg =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setIsValidateEmail(!!event.target.value.match(reg));
    setValues({
      ...values,
      email: event.target.value
    });
  };

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.role === '') {
      validComplete = false;
      formErrors.role = false;
      showAlert('error', 'Поле роль не должно быть пустым', resetDisabled);
    }

    if (values.email === '') {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Поле почта не должно быть пустым', resetDisabled);
    } else if (!isValidateEmail) {
      validComplete = false;
      formErrors.email = false;
      showAlert('error', 'Вы передели в поле почта не корректные данные', resetDisabled);
    }

    if (values.username === '') {
      validComplete = false;
      formErrors.username = false;
      showAlert('error', 'Поле логин не должно быть пустым', resetDisabled);
    } else if (/\s/.test(values.username)) {
      validComplete = false;
      formErrors.username = false;
      showAlert('error', 'Поле логин не должно содержать пробелы', resetDisabled);
    }

    if (values.password === '') {
      validComplete = false;
      formErrors.password = false;
      showAlert('error', 'Поле Пароль не должно быть пустым', resetDisabled);
    } else if (values.password.length < 8) {
      validComplete = false;
      formErrors.password = false;
      showAlert('error', 'Пароль должен содержать более 8 символов', resetDisabled);
    }

    if (values.lastname === '') {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Поле ФИО не должно быть пустым', resetDisabled);
    }

    if (values.confirm === '') {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Поле подтвердить пароль не должно быть пустым', resetDisabled);
    } else if (values.confirm.length < 8) {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Пароль должен содержать более 8 символов', resetDisabled);
    } else if (values.confirm !== values.password) {
      validComplete = false;
      formErrors.confirm = false;
      showAlert('error', 'Пароли должны совпадать', resetDisabled);
    } 
    
    if (values.phone.length > 0 && values.phone.length < 10) {
      validComplete = false;
      formErrors.username = false;
      showAlert('error', 'Телефон не должен содержать менше 10 цифр', resetDisabled);
    }

    setErrors(formErrors);
    return validComplete;
  };

  const roleTranslageMap = {
    user: { translate: 'Сотрудник', field: 'user' },
    admin: { translate: 'Админ', field: 'admin' },
    super_admin: { translate: 'Супер админ', field: 'super_admin' },
    supervisor: { translate: 'Руководитель', field: 'supervisor' }
  };

  const clearForm = () => {
    setValues({
      username: '',
      lastname: '',
      email: '',
      phone: '',
      password: '',
      confirm: ''
    });
  };

  const submit = async () => {
    if (validate()) {
      setIsShowLoader(true);
      setSubmitDisabled(true);

      let role = null;

      for (let key in roleTranslageMap) {
        const current = roleTranslageMap[key];
        if (current.translate === values.role) {
          role = current.field;
        }
      }
      const data = {
        username: values.username,
        email: values.email,
        role,
        password: values.password,
        fio: values.lastname
      };

      if (values.phone.length > 0) {
        data.phone = values.phone
      }

      postU('admin/users', data)
        .then((resp) => {
          if (resp.status === 'success') {
            showAlert('success', 'Пользователь создан успешно', resetDisabled);
            clearForm();
          }
        })
        .catch((err) => {
          console.error(err);
          showAlert(
            'error',
            'Ошибка при создании пользователя, возможно такое имя или e-mail уже существует'
            , resetDisabled
          );
        })
        .finally(() => {
          setIsShowLoader(false);
          setSubmitDisabled(false);
        });
    }
  };
  useEffect(() => {
    setIsLoaded(true);
    getU(`roles`)
      .then((resp) => {
        if (resp.status === 'success') {
          const translateRolesArray = [];
          resp.data.roles.forEach((item) => {
            translateRolesArray.push(roleTranslageMap[item].translate);
          });
          setRoles(translateRolesArray);
          setValues({ ...values, role: translateRolesArray[0] });
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке ролей, попробуйте перезайти'
          , resetDisabled
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Создать нового пользователя" />
      <NavigateHeader />
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Создать нового пользователя" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Введите логин"
                    margin="normal"
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={values.username}
                    variant="outlined"
                    error={errors.username}
                  />
                  <TextField
                    fullWidth
                    label="Введите ФИО"
                    margin="normal"
                    name="lastname"
                    onChange={handleChange}
                    type="text"
                    value={values.lastname}
                    variant="outlined"
                    error={errors.lastname}
                  />
                  <TextField
                    fullWidth
                    label="Введите почту"
                    margin="normal"
                    name="email"
                    onChange={handleChangeEmail}
                    type="text"
                    value={values.email}
                    variant="outlined"
                    error={errors.email}
                  />
                  <TextField
                    fullWidth
                    label="Введите номер телефона"
                    margin="normal"
                    name="phone"
                    onChange={handleChange}
                    type="number"
                    value={values.phone}
                    variant="outlined"
                    error={errors.phone ? true : false}
                  />
                  <TextField
                    fullWidth
                    label="Введите пароль"
                    margin="normal"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                    error={errors.password}
                  />
                  <TextField
                    fullWidth
                    label="Повторите пароль"
                    margin="normal"
                    name="confirm"
                    onChange={handleChange}
                    type="password"
                    value={values.confirm}
                    variant="outlined"
                    error={errors.confirm}
                  />
                  <DropDownSelect
                    value={values.role}
                    onChange={handleChange}
                    seleckList={roles}
                    label="Роль"
                    name="role"
                  />
                  <AlertInputsNotification alert={alert} />
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Создать
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UserAdd;
