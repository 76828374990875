import { useEffect, useState } from "react";
import { AlertInputsNotification } from "../alert-inputs-notification/AlertInputsNotification";
import { useShowAlert } from "../../shared/hooks/useShowAlert";
import { Box, Button, Card, CardContent, CardHeader, Container, Divider } from "@material-ui/core";
import {  useGet, usePost, usePut } from "../../API/request";

type UploadImgList = { id: number, values: File | null, src: string, change: boolean, update: boolean}[]


type Props = {
  // qrList: { id: number, company_id: number, filename: string }[];
  id: string;
  // fetchQr: () => void;
}

const CompanyEditQr = ({ id  }: Props) => {
  const { alert, showAlert } = useShowAlert();
  const [nothingChanged, setNothingChanged] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [uploadedImg, setUploadedImg ] = useState<UploadImgList | null>(null);
  const [qrList, setQrImages] = useState<{ id: number, company_id: number, filename: string }[]>([]);

  const postU = usePost();
  const putU = usePut();
  const getU = useGet();

  const updateuploadImg = (qrList: {id: number, company_id: number, filename: string }[]) => {
    const result: UploadImgList = [];
    for (let i = 0; i < 3; i++) {
      const qrIndex = qrList[i];
      if (!qrIndex) {
        result.push({ id: i, src: '/static/images/defphoto.jpg', values: null, change: false, update: false })
      } else {
        const currentImg = `${process.env.REACT_APP_API_URL}/uploads/qrs/${qrIndex.filename}`
        result.push({ id: i, src: currentImg, values: null, change: false, update: true })
      }
    }

    setUploadedImg(() => [...result]);
  }

  const fetchQr = () => {
    const endpoint = `companies/${id}/qrs`;

    getU(endpoint)
    .then((resp: any) => {
      if (resp.status === 'success') {
        const { qrs } = resp.data;
        setQrImages(qrs);
        updateuploadImg(qrs);
      } 
    })
    .catch((e:any) => console.log(e))
  }


  useEffect(() => {
    fetchQr();
  },[])



  const avaUploaded = (event: any, index: number) => { 
    if (nothingChanged) {
      setNothingChanged(false);
    }

    if (!uploadedImg) return;

    const file: File = event.target.files[0];
    const src = URL.createObjectURL(file);

    const updatedUploadedImg =  [...uploadedImg];
    updatedUploadedImg[index].src = src;
    updatedUploadedImg[index].values = file;
    updatedUploadedImg[index].change = true;

  setUploadedImg(updatedUploadedImg);
  };

  const createQr = async(file: File) => {
    const endpoint = `admin/companies/${id}/qr`;
    let data = new FormData();
    data.append('qr', file);

    postU(endpoint, data)
          .then((resp: any) => {
            if (resp.status === 'success') {
              fetchQr();
              showAlert('success', 'Данные были успешно обновлены');
            } else {
              showAlert('error', 'Error');
            }
          })
          .catch(() => {
            showAlert('error', 'Ошибка сервера');
    })
    
   
      setUploadedImg(prev => {
        if (!prev) return null;
        return prev.map(item => {
          if (item.values === file) {
            return { ...item, update: true }
          } else {
            return { ...item }
          }
        })
      })
    
  }

  const updateQr = async(file: File, fielId: number) => {
    const oldFilename = qrList[fielId].filename;
    const endpoint = `admin/companies/${id}/qr?filename=${oldFilename}`;

    let data = new FormData();
    data.append('qr', file);

    putU(endpoint, data)
    .then((resp: any) => {
      if (resp.status === 'success') {
        fetchQr();
        showAlert('success', 'Данные были успешно обновлены');
      } else {
        showAlert('error', 'Error');
      }
    })
    .catch((e: any) => {
      console.log(e)
      showAlert('error', 'Ошибка сервера');
    })
  }


  const submitAvatar = async () => {
    if (!uploadedImg) return;

    if (nothingChanged) {
      showAlert('error', 'Нет изменений');
      return;
    }

    setSubmitDisabled(true);

    for await (let img of uploadedImg) {
      if (img.change && img.values instanceof File) {
       
        if (!img.update) {
          createQr(img.values);
        } else {
          updateQr(img.values, img.id);
        }
    
        
      }
    }
    
    setSubmitDisabled(false);
    setNothingChanged(true);
  };


  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3 }}>
        <Container maxWidth={false}>
        <AlertInputsNotification alert={alert} />
          <Box
            sx={{
              paddingBottom: 1
            }}
          >
            <form>
              <Card>
                <CardHeader title="QR код для вставки в протоколы" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <ul style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>  

                  {uploadedImg && uploadedImg.map((qr, index) =>
                    <li  key={`${qr.src}${index}`} className="itemWrapper">
                    <div className="container">

                      <input 
                        accept="xlsx/*"
                        type="file"
                        style={{ display: 'none' }}
                        id={String(`${qr.id}${qr.src}`)}
                        onChange={(event) => avaUploaded(event, index)}
                        />
                      <label htmlFor={String(`${qr.id}${qr.src}`)}>
                        <img  src={qr.src} className="itemImg" />
                        <div  className="middle"/>
                      </label>
                    </div>
                  </li>
                    
                    )}
                </ul>

                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submitAvatar}
                    disabled={submitDisabled}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
  );
};

export { CompanyEditQr };