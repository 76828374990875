import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Divider
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import { useGet, useDelete, usePost } from '../../API/request';
import '../../styles/All.css';
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableFooter, TablePagination } from '@mui/material';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';


import { useConfirm } from '../../components/Confirm';

const ProtocolsImportList = () => {
  const getU = useGet();
  const deleteU = useDelete();
  const postU = usePost();
  const [isLoaded, setIsLoaded] = useState(true);
  const [reports, setReports] = useState([]);
  const [date, setDate] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [files, setFiles] = useState([]);
  const { alert, showAlert } = useShowAlert();
  const confirm = useConfirm();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};


const handleFileChange = (event) => {
 
  setFiles([...event.target.files]);
};

const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
};

const handleImport = async (e) => {
  e.preventDefault()
  setIsLoaded(true); 
  const data = localStorage.getItem("access_token");
  

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/document`, {
      method: 'GET',
      headers: {
      Authorization: `Bearer ${data}`
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  const date = new Date(Date.now()).toISOString().split("T")[0]
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `Результат импорта ${date}`); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); 
    loadReports();
  } catch (error) {  

    setIsLoaded(false);
    showAlert('error', 'Нет новых документов для импорта');
  } finally {
    setIsLoaded(false);
  }
};



const handleDelete = (e, id) => {
  e.preventDefault()


  let endpoint = `document/list/${id}`;
  confirm({
    title: 'Удалить',
    content: 'Вы уверены, что хотите удалить запись?',
    onConfirm: () => {
        deleteU(endpoint)
            .then((resp) => {
              console.log(resp)
                if (resp.status === 'success') {
                    loadReports();                 
                }
            })
            .catch((e) => {
              showAlert('error', 'Не удалось удалить запись');
            });
    }
});
  
};


const handleSendDocuments = (e) => {
  e.preventDefault()


  let endpoint = `document/upload`;

  const uplodedFiles = new FormData();

  files.forEach(file => 
    {
    uplodedFiles.append('files', file)});
    postU(endpoint, uplodedFiles)
    .then((resp) => {
    if (resp.status === 'success') {
      setFiles([])
        showAlert('success', 'Документы были успешно загружены');
       
    } else {
        showAlert('error', 'Не удалось загрузить документы');
    }
})
.catch((error) => {
  console.log(error)
    showAlert('error', 'Не удалось загрузить документы');
});
  
};


  const loadReports = () => {
    setIsLoaded(true);
    let endpoint = `document/list?limit=${limit}&page=${page +  1}`;

    if(date) {
       endpoint = `document/list?limit=${limit}&page=${page +  1}&created_at=${date}`;
    }

    getU(endpoint)
    .then(resp => {
      if (resp.status === 'success') {
        const { reports, currentCount, totalCount } = resp.data;

        
        setReports(reports.data);
        if (currentCount) {
          setCount(currentCount)
        }
      }
    })
    .catch(error => console.log(error))
    .finally(() => setIsLoaded(false))
  };

  

  useEffect(() => {
    loadReports();
  },[page, limit, date])

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title='Импорт данных' />
      <Box className="headerWrapper">
      
        <Box className="headerTitle">
          Импорт данных
        </Box>
      </Box>
      <AlertInputsNotification alert={alert} />
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%', py:  3 }}>
        <Container maxWidth={false}>
      
          {isLoaded ? (
            <UserListSkelet />
          ) : (
            <>
              <Box sx={{ pt:  3}}>
  
                <Card sx={{pt:5}}>
           
                  <div>
                  <form accept-charset="UTF-8">
                  <label htmlFor="fileUpload" style={labelStyle}>Загрузка документов на сервер</label>
            <input
            id='fileUpload'
                type="file"
                accept=".xml" 
                multiple
                onChange={handleFileChange}
                style={inputUploadStyle}
            />
            </form>
            {files.length > 0 && (
                <ul style={listStyle}>
                    {files.map((file, index) => (
                        <li key={index} style={listItemStyle}>
                            {file.name}
                        </li>
                    ))}
                    <Button onClick={(e) => handleSendDocuments(e)} sx={{m: "5px"}} color="primary"
            variant="text">
        Отправить документы на сервер
    </Button>
                </ul>

                
            )}  
                  </div>
               <Box display={'flex'} alignItems="end" justifyContent={'space-between'} >
                  <div>
             
                  <label htmlFor="dateInput" style={labelStyle}>Фильтр по дате импорта</label>
                     
      <input id="dateInput" value={date} onChange={(e) => setDate(e.target.value)} type="date" style={inputStyle} />
      </div>
              
      <Button sx={{m: 1}} onClick={(e) => handleImport(e)}    color="primary"
                                          variant="contained">
                                      Начать импорт загруженных документов
                                  </Button>
                                  </Box>
            
                  <PerfectScrollbar>
                    <Box sx={{ minWidth:  1400 }}>
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width:  100 }}>
                              Id
                            </TableCell>
                            <TableCell x={{ width:  180 }}>
                            Название документа
                            </TableCell>
                            <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                            Протокол
                            </TableCell>
                            <TableCell sx={{ width:  100,  textAlign: 'center'}}>
                            Импортировано
                            </TableCell>
                            <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                           Ошибка импорта
                            </TableCell>
                            <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                           Дата импорта
                            </TableCell>
                            <TableCell sx={{ width:  150, textAlign: 'center'  }}>
                       
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {reports && reports.map((report) => (
                            <TableRow hover key={report.id}>
                              <TableCell sx={{ width:  100 }}>
                                {report?.id}
                              </TableCell>
                              <TableCell sx={{ width:  180 }}>
                                {report?.name || '---'}
                              </TableCell>
                              <TableCell sx={{ width:  100, textAlign: 'center'   }}>
                                {report?.protocol || '---'}
                              </TableCell>
                              <TableCell sx={{ width: 100, textAlign: 'center' }}>
                                {report?.imported ? "Да" : "Нет"}
                              </TableCell>
                              <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                                {report?.error || '---'}
                              </TableCell>
                              <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                                {report?.created_at.split('T')[0] || '---'}
                              </TableCell>
                            
                              <TableCell sx={{ width:  100, textAlign: 'center'  }}>
                              <Button onClick={(e) => handleDelete(e, report?.id)}   sx={{ margin: 'auto', display: 'block' }} color="primary"
                                          variant="contained">
                                      Удалить
                                  </Button>
                              </TableCell>
                                 
                              
                      
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Количество строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Card>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ProtocolsImportList;




const labelStyle = {
  display: 'block',
  marginBottom: '5px', 
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: '1rem',
  color: '#000',
  paddingTop: '10px',
  paddingLeft: '10px',

};

const inputStyle = {
  width: '250px',
  padding: '10px',
  margin: "8px",
  display: 'block',
  border: '1px solid #ccc',
  borderRadius: '4px',
  boxSizing: 'border-box',
  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  fontSize: '1rem',
  backgroundColor: '#fff', 
  color: '#000', 
  cursor: 'pointer', 
  outline: 'none', 
  lineHeight: 'normal',
};




const inputUploadStyle = {
  padding: '10px 15px',
  margin: '10px 0',
  display: 'block',
  backgroundColor: '#fff',
  borderRadius: '4px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '1rem',
  cursor: 'pointer',
  width: '100%',
  boxSizing: 'border-box',
  color: '#555',
  lineHeight: '1.5'
};

const listStyle = {
  listStyle: 'none',
  padding: 0,
  marginTop: '10px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '1rem',
  color: '#555',
};

const listItemStyle = {
  padding: '8px 16px',
  borderBottom: '1px solid #ddd',
  cursor: 'default',
};

