//@ts-ignore
import { AxiosService } from '../shared';


export const usePost = () => {
    return function(path: string, payload?: object, config?: string) {
 
        
        return  AxiosService.post(path, payload, config)
        .then((data: { data: any; }) => data.data)
        
    }
}

export const useGet = () => {
    return function(path: string, config?: string)  {
        return  AxiosService.get(path, config)
        .then((data: { data: any; }) => data.data)
        
    }
}

export const usePut = () => {
    return function(path: string, payload: object, config?: string) {
        return AxiosService.put(path, payload, config)
        .then((data: { data: any; }) => data.data)
    }
}


export const useDelete = () => {
    return function(path: string, payload?: object, config?: string) {
        return AxiosService.delete(path, payload, config)
        .then((data: { data: any; }) => data.data)
    }
}


export const usePatch = () => {
    return function(path: string, payload: object, config?: string) {
        return AxiosService.path(path, payload, config)
        .then((data: { data: any; }) => data.data)
    }
}


export const get = async(path: string, config?: string) => {
  return await AxiosService.get(path, config)
}

export const post = async(path: string, payload: object, config?: string) => {
  return  AxiosService.post(path, payload, config)
}

export const put = async(path: string, payload = {}) => {
    return  AxiosService.put(path, payload)
}


export const deleteRequest = async(path: string, payload = {}, config?: string) => {
    return  AxiosService.delete(path, payload, config)
}
   

export const patch =  async(path: string, payload = {}) => {
    return  AxiosService.path(path, payload)
}

export const httpDelete = async(path: string, config?: string) => {
    return  AxiosService.delete(path, config)
}

