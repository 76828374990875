export const API_URL = process.env.REACT_APP_API_URL;
export const ACCESS_TOKEN_LOCALE_STORAGE = 'access_token';
export const ACCESS_TOKEN_COOKIE = 'access_token';
export const REFRESH_TOKEN_LOCALE_STORAGE = 'refresh_token';
export const TOKEN_RECEIVED_LOCALE_STORAGE = 'token_received';
export const USER_LOCALE_STORAGE = 'user';

const headers = {};

export const AxiosConfig = {
  baseURL: API_URL,
  timeout: 10000,
  headers
};
