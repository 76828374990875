import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'; 
//@ts-ignore
import {en} from "dayjs/locale/en"; 
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {Box, TextField } from '@material-ui/core';

type DatePickerMainProps = {
  handleChangeDate: () => void;
  value: Date
  label: string
}

const DatePickerMain = ({ handleChangeDate, value, label }: DatePickerMainProps) => {
  return (
    <LocalizationProvider locale={en} dateAdapter={AdapterDateFns}>
    <Box sx={{pt: 2}}>
          <DatePicker
            //@ts-ignore
            sx={{ width: '100%', margin: 20 }}
            label={label}
            inputFormat="dd-MMMM-yyyy"
            value={value}
            onChange={handleChangeDate}
            //@ts-ignore
            renderInput={(params) => <TextField {...params} />}
          />
    </Box>
        </LocalizationProvider>
  );
};

export  {DatePickerMain};