import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Divider,
  TableFooter,
  TablePagination,
  Avatar
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from "../../skeletons/UserListSkelet";
import { Loader } from '../../components/loader/Loader';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableTitlePage } from '../../components/table-title-page/TableTitlePage'

const MessagesList = () => { 
  const confirm = useConfirm();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [messages, setMessages] = useState([])
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const getU = useGet();
  const deleteU = useDelete();

  const example = {
    lastMessage: {
        date: "2024-01-04T14:40:48.000Z",
        id: 33,
        message: 'good evening',
        reciever_id: 84,
        user_id: 1,
    },
    user: {
        avatar: 'ff850f1e-b70e-4977-a704-761f8efa8830.jpg',
        company_id: 29,
        email: "lawrova.44@yandex.ru",
        fio: "Даша Шамота",
        id: 84,
        phone: "75836914742",
        role: "supervisor",
        username: "lawrova44"
    }
  }

  const fethAllMessages = async() => {
    setIsDataLoading(true)
    const endpoint = `messages/admin/chats?limit=${limit}&page=${page + 1}`;

    getU(endpoint)
    .then((resp) => {
      if (resp.status === 'success') {

        setMessages(resp.data.messages)

        if (resp.data.totalCount) {
            setCount(resp.data.totalCount);
        }
      }

    })
    .catch((err) => {
      setMessages([]);
      setCount(0);
  })
  .finally(() => {
    setIsDataLoading(false)
  });
    
  }

  const onDelete = (id) => {
    confirm({
        title: 'Удалить',
        content: 'Вы уверены, что хотите удалить сообщение?',
        onConfirm: () => {
            deleteU(`messages/${id}`)
                .then((resp) => {
                    console.log(resp)
                    if (resp.status === 'success') {
                      fethAllMessages()
                    }
                })
                .catch((e) => {
                    // console.log("opened")
                    // console.log(e.response)
                });
        }
    });
};


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
};

const handleChangeLimit = (event) => {
    setLimit(event.target.value);
    setPage(0);
};

useEffect(() => {
    fethAllMessages();
}, [page, limit]);

  if (isDataLoading ) {
    return  <Loader/>;
}

  return (
    <>
            <HelmetPage title='Сообщения' />
            <TableTitlePage title='Сообщения' />
            
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Аватар 
                                                            </TableCell>
                                                            <TableCell sx={{width: 250}}>
                                                                ФИО
                                                            </TableCell>
                                                            <TableCell sx={{width: 150}}>
                                                                Телефон
                                                            </TableCell>
                                                            <TableCell sx={{width: 200}}>
                                                                Почта
                                                            </TableCell>
                                                            <TableCell sx={{width: 600}}>
                                                                Сообщение
                                                            </TableCell>
                                                            <TableCell sx={{width: 120}}>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {messages && messages.map((message) => (
                                                            <TableRow hover key={message?.lastMessage.reciever_id}>
                                                            <TableCell  >
                                                            <Avatar 
                                                                alt='User avatar'
                                                                src={message?.user.avatar ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${message.user.avatar}` : ''}
                                                            />
                                                             </TableCell>
                                                                {/* <TableCell sx={{width: 100}}>
                                                                    {message?.user.avatar}
                                                                </TableCell> */}

                                                                <TableCell  >
                                                                    {message?.user.fio || '---'}
                                                                </TableCell>
                                                                <TableCell  >
                                                                    {message?.user.phone || '---'}
                                                                </TableCell>
                                                                <TableCell  >
                                                                    {message?.user.email || '---'}
                                                                </TableCell>

                                                                <TableCell >
                                                                    {message?.lastMessage.message || '---'}
                                                                </TableCell>
                                                                <TableCell>

                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                                                                        <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
                                                                            <Link to={`/app/messages/${message?.lastMessage.reciever_id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Ответ
                                                                                </Button>
                                                                            </Link>
                                                                        {/* <Box sx={{ml: 2}}>
                                                                            <Button color="error"
                                                                                 onClick={() => onDelete(message.id)}
                                                                                 variant="contained">
                                                                                 Удалить
                                                                                 </Button>
                                                                            </Box>    */}
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>

                                                    <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Количество строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter>

                                                    
                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
    </>
  );
};

export { MessagesList };