import React, { useState, useEffect } from 'react';
import { Box, Container, Button, Card, CardContent } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { Typography } from '@mui/material';
import { RoomInfoRemoveCard } from '../../components/Rooms/RoomInfoRemoveCard';

const RoomInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentData, setCurrentData] = useState({});
  const [currentCompany, setCurrentCompany] = useState(null);

  const fetchCompanyInfo = (id) => {
    getU(`companies/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCurrentCompany(resp.data.company);
        } else {
          throw resp.message;
        }
      })
      .catch((error) => {
        showAlert('error', `Ошибка сервера: ${error}`);
      })
      .finally(() => {
        setIsLoaded(false);
      });
  };

  function formatDate(dateString) {
    if (!dateString) {
      return '---';
    }
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  useEffect(() => {
    setIsLoaded(true);
    getU(`placements/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCurrentData(resp.data.placement);
          const companyId = resp.data.placement.company_id;
          if (companyId) {
            fetchCompanyInfo(resp.data.placement.company_id);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Информация помещения" />
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Помещения</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
          {currentData && (
            <Card>
              <CardContent sx={{ p: 3 }}>
                <PerfectScrollbar>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      ID
                    </Typography>
                    <Typography variant="body1">{currentData?.id}</Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      Компания
                    </Typography>
                    <Typography variant="body1">
                      {currentCompany?.name ? currentCompany.name : '---'}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      ФГИС
                    </Typography>
                    <Typography variant="body1">
                      {currentData?.fgis_number}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      Площадь
                    </Typography>
                    <Typography variant="body1">
                      {currentData?.square}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      Наличие специального оборудования
                    </Typography>
                    <Typography variant="body1">
                      {currentData?.special_equipment}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: 5
                    }}
                  >
                    <Typography
                      sx={{ fontWeight: 600, minWidth: 320 }}
                      variant="subtitle1"
                    >
                      Специальное или приспособленное
                    </Typography>
                    <Typography variant="body1">
                      {currentData?.type_laboratory}
                    </Typography>
                  </div>
                  <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
                    Назначение помещения
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">
                    {currentData?.direction}
                  </Typography>
                  <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
                    Место нахождения или иная уникальная идентификация
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">
                    {currentData?.location}
                  </Typography>

                  <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
                    Перечень контролируемых параметров в помещении
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">
                    {currentData?.configurations}
                  </Typography>

                  <Typography sx={{ fontWeight: 600 }} variant="subtitle1">
                    Право собственности или иное законное основание
                  </Typography>
                  <Typography sx={{ pb: 2 }} variant="body1">{`${
                    currentData?.right_to_use ? currentData?.right_to_use : ''
                  }: ${
                    currentData?.details_of_use
                      ? currentData?.details_of_use
                      : ''
                  }`}</Typography>
                </PerfectScrollbar>
              </CardContent>
            </Card>
          )}
        </Container>
      </Box>

      {id && <RoomInfoRemoveCard id={id} />}
    </>
  );
};

export default RoomInfo;
