import { configureStore } from '@reduxjs/toolkit'
//@ts-ignore
import { combineReducers } from "@reduxjs/toolkit";

//@ts-ignore
import { authReducer } from './slices/auth';

export const rootReducer = combineReducers({
    auth: authReducer,
  });

export const store = configureStore({
    reducer: rootReducer
})

export type AppDispatch = typeof store.dispatch;

