import {
  ACCESS_TOKEN_LOCALE_STORAGE,
  REFRESH_TOKEN_LOCALE_STORAGE,
  TOKEN_RECEIVED_LOCALE_STORAGE,
  USER_LOCALE_STORAGE
} from '../axios/config';
import { CookieService } from '../../../shared/service';
import { fetchUrl, fethConfig } from './config';
import { fetchRefresh } from './utils';


export const baseFetch = async ({ payload, url, params, method = 'GET' }) => {
  const _config = fethConfig(method, payload);
  const _url = fetchUrl(url, params);

  try {
    let response = await window.fetch(_url, _config);

    if (response.status === 401) {
      const refresh = await fetchRefresh()

      if (refresh.status === 'error') {
        return refresh
      } else {
        const { accessToken, refreshToken, user } = refresh

        setLocalStorage(accessToken, refreshToken, user);
        
        _config.headers.Authorization = `Bearer ${accessToken}`;
        
        response = await fetch(_url, _config);
      }
    }

    return await response.json();
  } catch (error) {
    console.error('Failed in base fetch:', error);
    throw error;
  }
};

const setLocalStorage = (accessToken, refreshToken, user) => {
  window.localStorage.setItem(ACCESS_TOKEN_LOCALE_STORAGE, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_LOCALE_STORAGE, refreshToken);

  window.localStorage.setItem(USER_LOCALE_STORAGE, JSON.stringify(user));
  window.localStorage.setItem(TOKEN_RECEIVED_LOCALE_STORAGE, Date.now() / 1000);
  CookieService.set(accessToken, ACCESS_TOKEN_LOCALE_STORAGE);
};
