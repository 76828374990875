import { useNavigate } from 'react-router-dom';

type Props = {
  item: string | null;
  label: string;
  widthLabel?: number;
  href?: string | null;
  target?: '_blank';
};

const InfoFieldItem = (props: Props) => {
  const { item, label, widthLabel = 200, href, target } = props;
  const navigate = useNavigate();

  return (
    <div className="wrap">
      <div className="label" style={{ minWidth: widthLabel }}>
        {label}
      </div>
      {item ? (
        <div className="text">
          {target === '_blank' && href && (
            <a href={href} target="_blank">
              <span style={href ? { color: 'blue', cursor: 'pointer' } : {}}>
                {item}
              </span>
            </a>
          )}

          {!target && (
            <span
              onClick={() => (href ? navigate(`${href}`) : {})}
              style={href ? { color: 'blue', cursor: 'pointer' } : {}}
            >
              {item}
            </span>
          )}
        </div>
      ) : (
        <div className="text">---</div>
      )}
    </div>
  );
};

export { InfoFieldItem };
