import { AxiosService } from "../../shared";


export default class AuthService {
  
  static async login(payload) {
    return  AxiosService.post('login', payload)
  }

  static async registration(payload) {
    return AxiosService.post('register', payload);
  }

  static async logout() {
    return AxiosService.get('logout');
  }
  
}
