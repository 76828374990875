import { useState } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardHeader,
  Divider
} from '@material-ui/core';

//@ts-ignore
import { useConfirm } from '../../components/Confirm/index';

import { useNavigate } from 'react-router';
import { useDelete } from '../../API/request';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../alert-inputs-notification/AlertInputsNotification';

const RoomInfoRemoveCard = ({ id }: { id: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { alert, showAlert } = useShowAlert();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const deleteU = useDelete();

  const remove = () => {
    confirm({
      title: 'Удалить',
      content: 'Вы уверены, что хотите удалить помещение?',
      onConfirm: () => {
        setIsLoading(true);

        (deleteU(`placements/${id}`) as Promise<any>)
          .then((resp: any) => {
            if (resp.status === 'success') {
              navigate('/app/rooms');
            } else {
              if (resp.message) {
                showAlert('error', resp.message);
              }
              throw resp.message;
            }
          })
          .catch((error: any) => {
            console.error(error);
          })
          .finally(() => setIsLoading(false));
      }
    });
  };

  return (
    <Box sx={{ backgroundColor: 'background.default', pt: 3, mb: 5 }}>
      <AlertInputsNotification alert={alert} />
      <Container maxWidth={false}>
        <Box>
          <Card>
            <CardHeader title="Удаление" />
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
              <Button
                color="error"
                variant="contained"
                onClick={remove}
                disabled={isLoading}
              >
                Удалить
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Box>
  );
};

export { RoomInfoRemoveCard };
