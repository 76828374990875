import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { useGet } from '../../API/request';
import '../../styles/All.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Loader } from '../../components/loader/Loader';
import { InfoFieldItem } from '../../components/info-field-item/InfoFieldItem'
import { HelmetPage } from '../../components/helmet/HelmetPage';

const ProtocolInfo = () => {
  const navigate = useNavigate();
  const getU = useGet();
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = useState(true);
  const [currentProtocol, setCurrentProtocol] = useState({});


  function formatDate(dateString) {
    if (!dateString) {
      return '---'
    }
    const date = new Date(dateString);
    return date.toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  }

  useEffect(() => {
    setIsLoaded(true);
    getU(`protocols/find/${id}`)
      .then((resp) => {
        if (resp.status === 'success') {
          setCurrentProtocol(resp.data.protocol)
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoaded(false);
      });
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title='Протокол' />
      <Box className="headerWrapper">
        <Box className="headerTitle">
          <Button startIcon={<ChevronLeft />} onClick={() => navigate(-1)}>
            Назад
          </Button>
        </Box>
        <ul className="headerList">
          <li onClick={() => navigate(-1)}>Протоколы</li>
          <li>/</li>
          <li>Информация</li>
        </ul>
      </Box>

      <Box sx={{ backgroundColor: 'background.default', pt: 3, pb: 1 }}>
        <Container maxWidth={false}>
        {currentProtocol &&
          <Card>
            <CardContent sx={{ p: 3 }}>
              <PerfectScrollbar>
                <div className="wrapAvatar">
                  <div className="info-block">
                    <InfoFieldItem label='ID Компании' item={currentProtocol?.company_id} />
                    <InfoFieldItem label='Основание' item={currentProtocol?.evidence} />
                    <InfoFieldItem label='Дата протокола' item={formatDate(currentProtocol?.date_creation)} />
                    <InfoFieldItem label='Номер протокола' item={currentProtocol?.number} />
                    <InfoFieldItem label='Статус' item={currentProtocol?.status} />
                    <InfoFieldItem label='Дата испытания' item={formatDate(currentProtocol?.date_of_test)} />
                    <InfoFieldItem label='Заказчик испытаний' item={currentProtocol?.customer_test} />
                    <InfoFieldItem label='Объект испытаний' item={currentProtocol?.object_test} />
                    <InfoFieldItem label='Дата поступления' item={formatDate(currentProtocol?.date_add_to_test)} />
                    <InfoFieldItem label='Место проведения' item={currentProtocol?.place_test} />
                    <InfoFieldItem label='Испытал' item={currentProtocol?.tester} />
                  </div>
                </div>
              </PerfectScrollbar>
            </CardContent>
          </Card>
        }
        </Container>
      </Box>
    </>
  );
};

export default ProtocolInfo;
