import { createAsyncThunk } from "@reduxjs/toolkit";
import AuthService from "../../API/auth/auth-api";

import TokenStorage from "../../API/TokenStorage";
import { CookieService } from '../../shared'

export const fetchAuthLogin = createAsyncThunk(
  "auth/fetchAuthLogin",
  async (payload, { rejectWithValue }) => {

    const response = await AuthService.login(payload);

    const { data: { user, tokens }, message, status } = response.data
    const { accessToken, refreshToken } = tokens
    
    if (status !== 'success' || !status) {
      return rejectWithValue(
        `${message}`
        );
      }

      CookieService.set(accessToken)
      
      TokenStorage.setAccessToken(accessToken);
      TokenStorage.setRefreshToken(refreshToken);
      TokenStorage.setUser(user);
      TokenStorage.setTokenReceived(Date.now() / 1000);
      
    return {
      accessToken: accessToken,
      refreshToken: refreshToken,
      user: user,
    };
  }
);

/**
 * status: string
 * "message": string
 *  data : {
 *  message: string
 *   }
 */

export const fetchAuthLogout = createAsyncThunk(
  "auth/fetchAuthLogout",
  async (_, { rejectWithValue }) => {

    CookieService.remove()
    TokenStorage.logOut()


    // const response = await AuthService.logout();
    
    // const { data: { message: dataMessage }, message, status } = response
    
    
    // if (status !== 'success' || !status) {
    //   return rejectWithValue(
    //     `${message}`
    //     );
    //   }

    return {};
  }
);