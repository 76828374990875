import React, {useState, useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import {
    Box,
    Container,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button,
    Divider,
    Avatar
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import UserListSkelet from '../../skeletons/UserListSkelet';
import {useDelete, useGet} from '../../API/request';
import {useConfirm} from "../../components/Confirm/index";
import '../../styles/All.css'
import { Loader } from '../../components/loader/Loader';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { TableTitlePage } from '../../components/table-title-page/TableTitlePage';
import { TableFooter, TablePagination, TextField } from '@mui/material';

const CompanyList = () => {

    const confirm = useConfirm();
    const getU = useGet();
    const deleteU = useDelete();
    const [searchParams, setSearchParams] = useSearchParams();

    const [isLoaded, setIsLoaded] = useState(true);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [companys, setCompanys] = useState([]);
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState(0);



    const [queryParams, setQueryParams] = useState({
        name: searchParams.get("name") || '',
        inn: searchParams.get("inn") || '',
    });

    const handleChangeQueryParams = (event) => {
        setQueryParams({
            ...queryParams,
            [event.target.name]: event.target.value
        });
    };

    const handleFilterQueryParams = () => {
        const params = {}
        if (queryParams.name !== '') {
            params.name = queryParams.name
        }
        if (queryParams.inn !== '') {
            params.inn = queryParams.inn
        }
       
        if (Object.keys(params).length !== 0) {
            setSearchParams(params)
        }
    }

    const loadCompany = () => {
        setIsDataLoading(true);
        setIsLoaded(true)
 
        let endpoint = `companies?page=${page + 1}&limit=${limit}`;

        if (queryParams.name !== '') {
            endpoint += `&name=${searchParams.get("name")}`;
        }
        if (queryParams.inn !== '') {
            endpoint += `&inn=${searchParams.get("inn")}`;
        }
        

        getU(endpoint)
            .then((resp) => {
                if (resp.status === 'success') {
                    const { companies, limit, page, totalCount } = resp.data
                    setCompanys(companies);
                    if (totalCount) {
                       setCount(totalCount); 
                    }
                } 
                setIsDataLoading(false);
            })
            .catch((err) => {
                setCompanys([]);
                setCount(0);
                setIsDataLoading(false);
            })
            .finally(() => {
                setIsLoaded(false)
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeLimit = (event) => {
        setLimit(event.target.value);
        setPage(0);
    };


    const onDelete = (id) => {
     
        confirm({
            title: 'Удалить',
            content: 'Вы уверены, что хотите удалить компанию?',
            onConfirm: () => {
                deleteU(`companies/${id}`)
                    .then((resp) => {
                        if (resp.status === 'success') {
                            loadCompany();
                        }
                    })
                    .catch((e) => {
                        // console.log("opened")
                        // console.log(e.response)
                    });
            }
        });
    };

    useEffect(() => {
        if (queryParams.name === '') {
            searchParams.delete("name")
            setSearchParams(searchParams);
        }
        if (queryParams.inn === '') {
            searchParams.delete("inn")
            setSearchParams(searchParams);
        }
    }, [queryParams])

    useEffect(() => {
        loadCompany();
    }, [page, limit, searchParams]);

    if (isLoaded) {
        return  <Loader/>;
    }

    return (
        <>
            <HelmetPage title='Компании' />
            <TableTitlePage title='Компании' />
            
            <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Box sx={{marginLeft: 2}}>
                                        <Link to="/app/company/add">
                                            <Button color="primary" variant="contained">
                                            Добавить новую компанию
                                            </Button>
                                        </Link>
                                    </Box>
                                </Box>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    mx: 2,
                                                    mb: 1
                                                }}>
                                                    <TextField
                                                        fullWidth
                                                        label="Название"
                                                        margin="normal"
                                                        name="name"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.name}
                                                        variant="outlined"
                                                        style={{width: '45%'}}
                                                    />
                                                    <TextField
                                                        fullWidth
                                                        label="ИНН"
                                                        margin="normal"
                                                        name="inn"
                                                        onChange={handleChangeQueryParams}
                                                        type="text"
                                                        value={queryParams.inn}
                                                        variant="outlined"
                                                        style={{width: '45%'}}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={handleFilterQueryParams}
                                                        sx={{mt: 2, mb: 1}}
                                                    >
                                                        Применить
                                                    </Button>
                                                </Box>
                                                <Divider/>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell sx={{width: 50}}>
                                                               
                                                            </TableCell>
                                                            <TableCell>
                                                                Название
                                                            </TableCell>
                                                            <TableCell>
                                                                ИНН
                                                            </TableCell>
                                                            <TableCell>
                                                             ID Руководителя
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {companys?.map((company, ind) => (
                                                            <TableRow hover key={`${ind}-${company.name}`}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {company?.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Avatar
                                                                        variant="rounded"
                                                                            src={
                                                                                company.avatar
                                                                                    ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${company.avatar}`
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </TableCell>

                                                                <TableCell style={{position: 'realitive'}} >
                                                                    {company?.name || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.inn || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {company?.supervisor_id || '---'}
                                                                </TableCell>
                                                                <TableCell>



                                                                    <Box sx={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end',}}>

                                                                        <Box sx={{display: 'flex', alignItems: 'flex-end',}}>
                                                                            <Link to={`/app/company/${company.id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Инфо.
                                                                                </Button>
                                                                            </Link>
                                                                            <Box sx={{ml: 2}}>
                                                                                <Link
                                                                                    to={`/app/company/edit/${company.id}`}
                                                                                    style={company?.role === "admin" ? {pointerEvents: 'none'} : {}}>
                                                                                    <Button color="primary"
                                                                                            variant="contained"
                                                                                            disabled={company?.role === "admin"}
                                                                                    >
                                                                                        Редак.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>

                                                                     
{/* ________________________________________________________________________________DELETE___________________________________________ */}
                                                                        <Box sx={{ml: 2}}>
                                                                            <Button color="error"
                                                                                 onClick={() => onDelete(company.id)}
                                                                                 variant="contained">
                                                                                 Удалить
                                                                                 </Button>
                                                                            </Box>
{/* _______________________________________________________________________________________________________________________________________ */}       

                                                                        </Box>
                                                                    </Box>




                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>

                                                     <TableFooter>
                                                        <TableRow>
                                                            <TablePagination
                                                                labelRowsPerPage={
                                                                    <span>Количество строк на странице:</span>}
                                                                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                                                colSpan={7}
                                                                count={count}
                                                                rowsPerPage={limit}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeLimit}
                                                            />
                                                        </TableRow>
                                                    </TableFooter> 

                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
        </>
    );
};

export default CompanyList;
