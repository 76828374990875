import {
  Box,
  Container,
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Link} from 'react-router-dom';
import React from 'react';

const WorkersList = ({workers, isDataLoading}) => {

  if (workers.length === 0) {
    return null;
  }

  return (
     <Box sx={{backgroundColor: 'background.default', minHeight: '100%', py: 3}}>
                <Container maxWidth={false}>
                    {
                        isDataLoading ?
                            <UserListSkelet/>
                            :
                            <>
                                <Box sx={{pt: 3}}>
                                    <Card>
                                        <PerfectScrollbar>
                                            <Box sx={{minWidth: 1400}}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{width: 80}}>
                                                                Id
                                                            </TableCell>
                                                            <TableCell>
                                                               Аватар
                                                            </TableCell>
                                                            <TableCell>
                                                                Логин
                                                            </TableCell>
                                                            <TableCell>
                                                                Email
                                                            </TableCell>
                                                            <TableCell>
                                                                Телефон
                                                            </TableCell>
                                                            <TableCell>
                                                                Роль
                                                            </TableCell>
                                                            <TableCell>

                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>
                                                        {workers.length > 0 && workers?.map((user) => (
                                                            <TableRow hover key={user.id}>
                                                                <TableCell sx={{width: 80}}>
                                                                    {user?.id}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{ alignItems: 'center', display: 'flex' }}>
                                                                        <Avatar
                                                                            src={
                                                                                user.avatar
                                                                                    ? `${process.env.REACT_APP_API_URL}/uploads/avatars/${user.avatar}`
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    </Box>
                                                                </TableCell>

                                                                <TableCell>
                                                                    {user?.username || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.email || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.phone || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {user?.role || '---'}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Box sx={{display: 'flex'}}>

                                                                        <Box sx={{display: 'flex'}}>
                                                                            <Link to={`/app/user/${user.id}`}>
                                                                                <Button color="primary"
                                                                                        variant="contained">
                                                                                    Инфо.
                                                                                </Button>
                                                                            </Link>
                                                                            <Box sx={{ml: 2}}>
                                                                                <Link
                                                                                    to={`/app/user/edit/${user.id}`}
                                                                                    style={user?.role === "admin" ? {pointerEvents: 'none'} : {}}>
                                                                                    <Button color="primary"
                                                                                            variant="contained"
                                                                                            disabled={user?.role === "admin"}
                                                                                    >
                                                                                        Редак.
                                                                                    </Button>
                                                                                </Link>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>

                                                </Table>
                                            </Box>
                                        </PerfectScrollbar>
                                    </Card>
                                </Box>
                            </>
                    }
                </Container>
            </Box>
  );
};

export default WorkersList;