import axios from 'axios';
import {
  ACCESS_TOKEN_LOCALE_STORAGE,
  API_URL,
  REFRESH_TOKEN_LOCALE_STORAGE,
  TOKEN_RECEIVED_LOCALE_STORAGE,
  USER_LOCALE_STORAGE
} from './config';
import { publicHttp } from './public-http';

export const interceprorsRequest = (config) => {
  const token = window.localStorage.getItem(ACCESS_TOKEN_LOCALE_STORAGE);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}

export const  interceptorsResponseConfig = (config) =>  {
  return config
};

export const  interceptorsResponseError = async (error) =>  {
  const origionalRequest = error.config;
  if (
    error.response.status == 401 &&
    error.config &&
    !error.config._isRetry
  ) {
    origionalRequest._isRetry = true;
    try {
      const refreshUrl = new URL(`${API_URL}/api/refresh`);
      const oldRefreshToken = window.localStorage.getItem(
        REFRESH_TOKEN_LOCALE_STORAGE
      );

      const response = await axios.get(refreshUrl, {
        headers: {
          Authorization: `Bearer ${oldRefreshToken}`
        }
      });


      const { data: { user, tokens }} = await response.data;
      const { accessToken, refreshToken } = tokens;

      await refresLocalStorage(accessToken, refreshToken, user)

      return publicHttp.request(origionalRequest);
    } catch (error) {
      console.log('Failed autorization');
      await removeLocalStorage()
    }
  }
  throw error;
};

async function refresLocalStorage(accessToken, refreshToken, user) {

  window.localStorage.setItem(ACCESS_TOKEN_LOCALE_STORAGE, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_LOCALE_STORAGE, refreshToken);

  window.localStorage.setItem(USER_LOCALE_STORAGE, JSON.stringify(user));
  window.localStorage.setItem(TOKEN_RECEIVED_LOCALE_STORAGE,Date.now() / 1000);
}

async function removeLocalStorage() {
  window.localStorage.removeItem(ACCESS_TOKEN_LOCALE_STORAGE);
  window.localStorage.removeItem(REFRESH_TOKEN_LOCALE_STORAGE);

  window.localStorage.removeItem(USER_LOCALE_STORAGE);
  window.localStorage.removeItem(TOKEN_RECEIVED_LOCALE_STORAGE); 
  window.location.href = '/login'
} 
