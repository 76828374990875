import { API_URL, REFRESH_TOKEN_LOCALE_STORAGE } from "../axios/config";

export async function fetchRefresh() {
  const refreshUrl = new URL(`${API_URL}api/refresh`);
  const refreshTokenLocaleStorage = window.localStorage.getItem(
    REFRESH_TOKEN_LOCALE_STORAGE
  );

      const fetchRefreshToken = await fetch(refreshUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshTokenLocaleStorage}`
      }
    });

    const json = await fetchRefreshToken.json();
    
    if (fetchRefreshToken.status === 200) {
      const { tokens: { accessToken, refreshToken, user } } = json.data;
      return { accessToken, refreshToken, user } 
    } else {
      return json
    }
}