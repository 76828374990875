import {
  Box,
  Container,
  Button,
  TextField,
  CardContent
} from '@material-ui/core';
import { useGet } from '../../API/request';
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import { Loader } from '../../components/loader/Loader';
import { FetchService } from '../../shared/api/fetch/service';
import { HelmetPage } from '../../components/helmet/HelmetPage';
import { NavigateHeader } from '../../components/navigate-header/NavigateHeader';
import { useShowAlert } from '../../shared/hooks/useShowAlert';
import { AlertInputsNotification } from '../../components/alert-inputs-notification/AlertInputsNotification';
import { DropDownSelect } from '../../components/drop-down-select/DropDownSelect';

const CompanyAdd = () => {
  const getU = useGet();

  const [isLoaded, setIsLoaded] = useState(true);
  const [values, setValues] = useState({
    companyName: '',
    inn: '',
  });
  const [errors, setErrors] = useState({
    companyName: false,
    inn: false,
  });

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [supervisorList, setSupervosorList] = useState([]);
  const [currentSupervosor, setCurrentSupervisor] = useState(null);
  const { alert, showAlert } = useShowAlert();


  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.name]: false
    });
  };

  const changeSelect = (value) => {
    const current = value.target.value;
    const findSupervisor = supervisorList.find(item => item.fio === current)
    if (current && findSupervisor) {
      setCurrentSupervisor(findSupervisor)
    }
  }

  const resetDisabled = () => setSubmitDisabled(() => false);

  const validate = () => {
    let validComplete = true;
    let formErrors = { ...errors };

    if (values.companyName === '') {
      validComplete = false;
      formErrors.companyName = false;
      showAlert('error', 'Поле Название компании не должно быть пустым', resetDisabled);
    } else if (values.inn.length < 10) {
      validComplete = false;
      formErrors.inn = false;
      showAlert('error', 'ИНН не должен быть меньше 10 символов', resetDisabled);
    }

    setErrors(formErrors);
    return validComplete;
  };

  const clearForm = () => {
    setValues({
      companyName: '',
      inn: '',
    });
  };

  const submit = async () => {
    if (!validate()) return;

    setIsShowLoader(true);
    setSubmitDisabled(true);

    const supervisorId =  supervisorList.find(item => item.fio === currentSupervosor.fio).id;

    if (!supervisorId) {
      return showAlert('success', 'Не указан руководитель компании');
    }

    const payload = {
      inn: values.inn,
      name: values.companyName,
      supervisor_id: String(supervisorId)
    };

    const createNewCompany = await FetchService.post({
      url: 'companies',
      payload
    });

    if (createNewCompany.status === 'success') {
      showAlert('success', 'Компания успешно добавлена', resetDisabled);
      clearForm();
    } else {
      if (createNewCompany.message.includes('Company with inn')) {
        showAlert('error', `Компания с инн ${values.inn} уже существует`, resetDisabled);
      } else {
        showAlert('error', `Ошибка: ${createNewCompany.message}`, resetDisabled);
      }
    }

    setIsShowLoader(false);
    setSubmitDisabled(false);

  };

  const fetchGetAllSupervisors = async() => {
    setIsLoaded(true);
  
    const endpoint = 'users/all/supervisors';

    getU(endpoint)
      .then((resp) => {
        if (resp.status === 'success') {
          if (resp.data.supervisors.length > 0) {
            setSupervosorList(resp.data.supervisors)
            setCurrentSupervisor(resp.data.supervisors[0])
          }
        }
      })
      .catch(() => {
        showAlert(
          'error',
          'Произошла ошибка при загрузке руководителей, попробуйте повторно загрузить'
          , resetDisabled
        );
      })
      .finally(() => {
        setIsLoaded(false);
      });
  }

  useEffect(() => {
    fetchGetAllSupervisors();
  }, []);

  if (isLoaded) {
    return <Loader />;
  }

  return (
    <>
      <HelmetPage title="Добавить новую компанию" />
      <NavigateHeader />
      <Box sx={{ backgroundColor: 'background.default', minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Box sx={{ pt: 2 }}>
            <form>
              <Card>
                <CardHeader title="Добавить новую компанию" />
                <Divider />
                <CardContent sx={{ position: 'relative' }}>
                  <TextField
                    fullWidth
                    label="Название компании"
                    margin="normal"
                    name="companyName"
                    onChange={handleChange}
                    type="text"
                    value={values.companyName}
                    variant="outlined"
                    error={errors.companyName}
                  />
                  <TextField
                    fullWidth
                    label="ИНН"
                    margin="normal"
                    name="inn"
                    onChange={handleChange}
                    type="number"
                    value={values.inn}
                    variant="outlined"
                    error={errors.inn ? true : false}
                  />
                  {currentSupervosor &&
                  <DropDownSelect
                  label='Руководитель'
                  name='supervisor_id'
                  onChange={(e) => changeSelect(e)}
                  seleckList={supervisorList.map(item => item.fio)}
                  value={currentSupervosor.fio}
                  />
                  }
                <AlertInputsNotification alert={alert} />
                </CardContent>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={submit}
                    disabled={submitDisabled}
                  >
                    Добавить
                  </Button>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CompanyAdd;
